<template>
  <div class="inside-bg" style="width:100%">
    <div class="container" style="padding-top:100px">
      <div class="row">
        <div class="col-lg-9">
          <div class="col shadow rounded-lg detail-box">
            <div class="row content user-banner">
              <div class="user-dp text-center">
                <div class="container">
                  <span class="visumeNotification" v-if="!visume"></span>
                  <div class="userDp">
                    <div class="video-input mt-2" v-if="!visume" >
                      <span class="userDummy"></span>
                    </div>
                    <div v-show="visume" style="padding:0;"  @click.prevent="openModal('visume')">
                      <span class="play-button"></span>
                      <video :key="visume_path" width="210" height="210" class="video-thumbnail" preload="metadata" style="background-color:#4d5b99 !important;"> 
                        <source :src="$S3_bucket_url+visume_path" type="video/mp4" id="visume_thumbnail"/>
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="user-details user-details-loading" v-if="user_loading">
                <p class="mb-0 lead user-name gradient" style="height:25px;width:200px;"></p>
                <p class="mb-0 lead user-designation gradient" style="height:15px;width:240px;margin-top:10px;"></p>
                <div class="row" style="margin-top:20px;margin-left:0px;">
                  <div class="gradient" style="height:15px;width:235px;"></div>
                  <div class="gradient" style="height:15px;width:150px;margin-left:12px;"></div>
                </div>
                <div class="gradient" style="height:15px;width:150px; margin-top:20px;"></div>
              </div>
              <div class="user-details" v-if="user && !user_loading">
                <div>
                  <p class="mb-0 lead user-name">{{ user.name }}</p>
                  <p class="mb-0 lead user-designation">{{designation}}</p>
                  <p class="mt-4 text-secondary">{{ user.email}}<span class="seperator" v-if="this.user.mobile">|</span>{{ this.user.mobile }}</p>
                  <p class="text-secondary" v-if="user_city">{{user_city}}</p>
                </div>
              </div>
          </div>
        </div>      
        <div class="tab-content shadow rounded-lg" id="v-pills-tabContent">
          <PersonalDetails :user="user" v-if="selectedTab=='personalDetails'"/>
          <EducationDetails :user="user" v-if="selectedTab=='educationalDetails'"/>
          <ExperienceDetails :user="user" v-if="selectedTab=='experienceDetails'"/>
        </div>
      </div>
        <div class="col-lg-3 mb-5 m-hide">
          <div class="content sidenav shadow rounded-lg mt-0 " style="background-color: white">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <div class="p-3 nav-link" :class="{'active': selectedTab == 'personalDetails'}" id="v-pills-personal-tab personalDetails" data-toggle="pill"  @click.prevent="selectTab('personalDetails')" role="tab" aria-controls="v-pills-personal" aria-selected="false">Personal details</div>
            <div class="p-3 nav-link" :class="{'active': selectedTab == 'educationalDetails'}" id="v-pills-education-tab educationalDetails" data-toggle="pill"  @click.prevent="selectTab('educationalDetails')" role="tab" aria-controls="v-pills-education" aria-selected="false">Education</div>
            <div class="p-3 nav-link" :class="{'active': selectedTab == 'experienceDetails'}" id="v-pills-workexperience-tab experienceDetails" data-toggle="pill"  @click.prevent="selectTab('experienceDetails')" role="tab" aria-controls="v-pills-workexperience" aria-selected="false">Work Experience</div>
          </div>
        </div>
      </div>
      </div>
      <b-modal id="visume_mode" ref="visume_mode" title="Visume" class="visume-Model" hide-footer no-close-on-backdrop>
        <div id="interactive" class="viewport">
          <div>
            <video id="video_resume" width="460" height="345" type="video/*" controls v-if="this.visume" :src="$S3_bucket_url+this.visume"></video>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
const PersonalDetails = () => import(/* webpackPrefetch: true */ "@/components/Candidate/Profile");
const EducationDetails = () => import(/* webpackPrefetch: true */ "@/components/Candidate/Education");
const ExperienceDetails = () => import(/* webpackPrefetch: true */ "@/components/Candidate/Experience");
import { mapState, mapGetters } from 'vuex';
import Actions from '../libraries/apiActions.js';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
import { DESIGNATIONS } from '../store/modules/designations/constants';
export default {
  name: "Public-profile",
  components:{
    'PersonalDetails': PersonalDetails,
    'EducationDetails': EducationDetails,
    'ExperienceDetails': ExperienceDetails,
  },
  async beforeMount() {
    window.scrollTo(0, 0);
    let url = window.location.href;
    let url_split = url.split('/');
    let user = url_split[url_split.length-1];
    let id = parseInt(user.replace(/\D/g,''));

    this.action.getCandidateProfile(id).then(res => {
      this.user_loading = false;
      this.user = res.candidate;
      this.getJobRole();
      this.getUser(this.user);
        // let routeData = this.$router.resolve({name: 'candidate_dashboard', params: {candidate:  res.candidate}});
        // window.open(routeData.href, '_blank');
        // this.$router.push({ name: 'candidate_dashboard', params: {candidate: res.candidate}});
    });
  },
    beforeCreate() {
      this.$store = store;
  },
  closeModal(){
    this.$refs['visume_mode'].hide();
  }, 
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
    this.cachedDispatch(DESIGNATIONS.GET_DESIGNATIONS, { param: apiFn });
    setTimeout(() => this.openPopUp = true, 2000);
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        // jobrole: state => state.jobrole.jobrole.jobrole,
        designations : state => state.designations.designations.designations
    }),
  },
  data() {
    return {
      visumeModal:{
        open:'exit',
      },
      action: new Actions(),
      user: {},
      contact: {
          email: '',
          mobile: ''
      },
      showModal: false,
      user_city: null,
      user_state: null,
      user_career: null,
      visume:'',
      visume_path:'',
      jobrole:[],
      selectedTab:'personalDetails',
      selectJob:true,
      selectPersonal:false,
      selectEducation:false, 
      selectExperience:false,
      selectCallLeter:false,
      selectFAQ:false,
      selectSettings:false,
      profileImage:null,
      selectedFile:null,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      designation:null,
      openPopUp:false,
      user_loading:true,
    };
  },
  methods: {
    openModal : function(){
      this.$refs['visume_mode'].show();
    },
    getUser(user) {
      this.user = user;
      this.user_city = user?.personal_details?.city?.city_name;
      this.user_state = this.user_city?.state?.state_name;
      this.user_career = this.user?.careerPreferencesDetail;
      this.visume = user?.personal_details?.video_resume_path;
      this.visume_path = user?.personal_details?.video_resume_path + '#t=0.1';
    },
    selectTab(tab){
      if(tab){
        this.selectedTab = tab;
      }
    },
     getJobRole: function(){
      this.action.getJobrole().then((data) => {
        data.jobroles.forEach((element) => {
          this.jobrole.push({
            value: element.role_id,
            text: element.role,
          });
          if(element.role_id == this.user_career.fst_prefered_role){
            this.designation = element.role;
          }else if(element.role_id == this.user_career.snd_prefered_role){
            this.designation = element.role;
          }else if(element.role_id == this.user_career.trd_prefered_role){
            this.designation = element.role;
          }
        });
      });
    },
    closeVisumePopup(){
      console.log('here popup');
      this.openPopUp = false;
    },
    viewContact: function(u_id){
            let req_body = {
                user_id: u_id,
                view_type: 1
            };
            let token = this.$cookies.get("admin_token");
            this.action.viewContact(req_body, token).then(res => {
                this.contact = res.contact;
                this.showModal = true;
            });
        },
  },
};
</script>
<style scoped>
video{
  background-color: #0d82ff;
}
.user-banner{
  background-image: linear-gradient(40deg, #3b63a4, #9a3b69);
  background-size: 100% 106px;
  background-repeat: no-repeat;
  margin-top: -15px;
}
.detail-box{
  margin-bottom: 20px;
}
.userDp{
  width: 157px;
  height: 157px;
  background-color: #0d82ff;
  
  border-radius: 90%;
  border: solid 2px #fff;
  overflow: hidden;
}
.userDummy{
  background-image: url("https://d22r5huxahsjvt.cloudfront.net/Assets/images/user-dummy.svg");
  position: absolute;
  width: 150px;
  height: 100%;
  left: 19px;
  right: 0px;
  top: 25px;
  opacity: 0.1;
}
.user-details{
  margin-top: 30px;
  line-height: 27px;
}
.user-details p.text-secondary{
  margin: 0px;
  line-height: 22px;
}
.user-details p span.seperator{
  margin: 0px 15px 0px 15px;
}
.user-name{
  font-size: 25px;
  color: white;
}
.user-designation{
  font-size: 18px;
  color: white;
}
.inside-bg{
  background: #e6e7e8;
}
.nav-pills .nav-link.active{
  background: #eef6ff;
  color: #333333;
}
.nav-pills .nav-link:hover{
  color: #007bff;
}
.v-application a{
  font-weight: bold;
  color: #333333;
  border-bottom: solid 1px #e7e7e7;
}
ul.nav-pills{
  padding: 0px !important;
}
.breadcrumb{
  background: none;
  margin-bottom: 5px;
  padding-left: 0px;
}
.breadcrumb .breadcrumb-item{
  color: #7a7a7a;
  font-weight: bold;
}
.breadcrumb .breadcrumb-item.active{
  color: #007bff;
}
.container {
    max-width: 1024px;
}
@media only screen and (max-width: 640px){
 .user-dp{
    margin: 0 auto;
  }
  .user-details{
    text-align: center;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .user-details p.mt-4{
    margin-top: 0px !important;
    
  }
  .user-name{
    color: #000;
  }
  .user-designation{
    color: #000;
  }
  .profileWrapper{
    padding: 7px !important;
  }
 
 

}
</style>

<style>
.video-input {
  border-radius: 10px;
  cursor: pointer;
  color: rgba(37, 38, 50, 0.5);
  padding: 10px 15px;
  width: 157px;
  height: 157px;
}
.user-dp{
  position: relative;
}

.visumeNotification{
  display: block;
  width: 21px;
  height: 21px;
  background: red;
  border-radius: 50%;
  position: absolute;
  right: 22px;
  top: 33px;
  border: solid 2px #fff;
}
.adVisumePopup::before{
  display: block;
  content: "";
  width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 8px solid #e30020;
  position: absolute;
  margin-top:-8px ;
  margin-left: 40px;
}
.popupWrapper{
  overflow: hidden;
  padding: 12px;
}
.closeBtn{
  position: absolute;
  right: 35px;
}
.adVisumePopup{
  
  background: #e3f6ff;
  
}
.adVisumePopup h2{
  font-size:20px;
  margin-top: 20px;
}
.adVisumePopup p{
  font-size: 14px;
}
.red-line{
  height: 3px;
  background: #e30020;
  display: block;
  margin: -12px -12px 10px -12px;

}
.video-input1 {
  border-radius: 10px;
  border: 2px dashed #00000000;
  cursor: pointer;
  color: rgba(37, 38, 50, 0.5);
  padding: 10px 15px;
}
.video-thumbnail{
  margin: -25px 0px 0px -28px;
  cursor: pointer;
  color: rgba(37, 38, 50, 0.5);
  filter: blur(1px);
}
span.play-button{
  width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-left: 20px solid rgb(255, 255, 255);
	border-bottom: 12px solid transparent;
  position: absolute;
  z-index: 50;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 77px;
  cursor: pointer;
}
.video-input:hover,.video-input1:hover {
  color: #007bff;
  
}
.add_image{
  position: absolute;
  top: 13px;
  left: 17px;
  opacity: 0;
}
.add_image:hover{
  opacity: 100;
}
.content{
    background: #fff;
    margin-top: 15px;
    padding: 15px;
    border-radius: 10px;
}
.shadow{
  box-shadow:0 0.5rem 1rem rgb(0 0 0 / 10%) !important
}

 .m-show
  {
    display: none;
  }

  .float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:25px;
	right:40px;
	background-color:#007bff;
	color:#FFF !important;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
  padding: 16px 0px;
  outline: none !important;
}

@media only screen and (max-width: 640px){
  .m-show
  {
    display: block;
  }
  .m-hide{
    display: none;
  }
 
}
</style>
